<template>
  <div class="success">
    <img src="../../../assets/images/huaban.png" />
    <div class="hint">您的信息已提交成功，我们将在1-3个工作日内审核，请您耐心等待。</div>
  </div>
</template>

<script>
  export default {
    name: 'success',
    data() {
      return {


      }
    },
    created() {

    },
    methods: {

    }

  }
</script>

<style lang="less" scoped>
  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;

    >img {
      width: 148px;
      height: 100px;
    }

    >.hint {
      font-weight: 400;
      color: #777777;
      margin-top: 20px;
    }
  }
</style>
