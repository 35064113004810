<template>
  <div class="shopInfo">
    <el-form :model="shopRegister" :rules="rules" ref="shopRegister" label-width="140px">
      <el-form-item label="店铺名称" prop="shop_name">
        <el-input v-model="shopRegister.shop_name" placeholder="请填写店铺名称" maxlength="50">
        </el-input>
      </el-form-item>
      <el-form-item label="商家姓名" prop="principal_name">
        <el-input v-model="shopRegister.principal_name" placeholder="请填写店铺负责人姓名" maxlength="20">
        </el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="shopRegister.phone" placeholder="请填写店铺负责人手机号" maxlength="11">
        </el-input>
      </el-form-item>
      <el-form-item label="其他联系人">
        <el-input type="textarea" v-model="shopRegister.other_contact" placeholder="请填写其他联系人姓名及手机号">
        </el-input>
      </el-form-item>
      <el-form-item label="经营品类" prop="companies_range">
        <el-cascader v-if="isShowCascader" style="width: 100%;" :props="propsOne"
          v-model="shopRegister.companies_range">
        </el-cascader>
      </el-form-item>
      <el-form-item label="退货联系人" prop="return_contact">
        <el-input v-model="shopRegister.return_contact" placeholder="请填写退货联系人姓名" maxlength="20">
        </el-input>
      </el-form-item>
      <el-form-item label="退货联系方式" prop="return_contact_phone">
        <el-input v-model="shopRegister.return_contact_phone" placeholder="请填写退货联系人手机号" maxlength="11">
        </el-input>
      </el-form-item>
      <el-form-item label="退货地址" prop="return_address">
        <el-cascader v-if="isShowCascader" style="width: 100%;" :props="props" v-model="shopRegister.return_address">
        </el-cascader>
      </el-form-item>
      <el-form-item label="退货详细地址" prop="return_address_info">
        <el-input type="textarea" v-model="shopRegister.return_address_info" placeholder="请填写退货详细地址" maxlength="100">
        </el-input>
      </el-form-item>
    </el-form>
    <div class="button-okz">
      <button class="go-one" @click="next('shopRegister')">提交</button>
      <button class="go-two" @click="nextBack()">返回上一步</button>
    </div>
  </div>
</template>

<script>
  import {
    areaList,
    getSaleCategory
  } from '../../../api/index'
  export default {
    name: 'shopInfo',
    data() {
      const return_address_info = (rule, value, callback) => {
        const n = Number(value);
        if (value === '') {
          callback(new Error('请输入退货详细地址'))
        } else if (!isNaN(n)) {
          callback(new Error('请输入正确退货详细地址'))
        } else {
          callback()
        }
      }
      return {
        propsOne: {
          lazy: true,
          async lazyLoad(node, resolve) {
            const {
              level
            } = node;
            const nodes = [];
            let code = level == 0 ? "0" : node.value;
            const res = await getSaleCategory({
              sale_category_id: code
            })
            if (res.flag === true) {
              res.categoryList.map(item => {
                nodes.push({
                  value: item.id,
                  label: item.categoryName,
                  leaf: level >= 1
                })
              })
              resolve(nodes);
            } else {
              this.$message.error(res.msg)
            }
          }
        },
        props: {
          lazy: true,
          async lazyLoad(node, resolve) {
            const {
              level
            } = node;
            const nodes = [];
            let code = level == 0 ? "000" : node.value;
            const res = await areaList({
              code: code
            })
            if (res.code === 0) {
              res.data.list.map(item => {
                nodes.push({
                  value: item.code,
                  label: item.name,
                  leaf: level >= 2
                })
              })
              resolve(nodes);
            } else {
              this.$message.error(res.msg)
            }
          }
        },
        shopRegister: {
          shop_name: '', //店铺名称
          principal_name: '', //负责人姓名
          phone: '', //负责人手机号
          other_contact: '', //其他联系人
          companies_range: [], //经营品类
          return_contact: '', //退货联系人
          return_contact_phone: '', //退货联系方式
          return_address: [], //退货地址
          return_address_info: '' //退货详细地址
        },
        rules: {
          shop_name: [{
            required: true,
            message: '请填写店铺名称',
            trigger: 'change'
          }],
          principal_name: [{
            required: true,
            message: '请填写店铺负责人姓名',
            trigger: 'change'
          }],
          phone: [{
              required: true,
              message: '请填写店铺负责人手机号',
              trigger: 'change'
            },
            {
              required: true,
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: '请输入正确的手机号码',
              trigger: ['blur', 'change']
            }
          ],
          companies_range: [{
            required: true,
            message: '请选择经营品类',
            trigger: 'change'
          }],
          return_contact: [{
            required: true,
            message: '请填写退货联系人姓名',
            trigger: 'change'
          }],
          return_contact_phone: [{
              required: true,
              message: '请填写退货联系人手机号',
              trigger: 'change'
            },
            {
              required: true,
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: '请输入正确的手机号码',
              trigger: ['blur', 'change']
            }
          ],
          return_address: [{
            required: true,
            message: '请选择地址',
            trigger: 'change'
          }],
          return_address_info: [{
            required: true,
            validator: return_address_info,
            trigger: 'change'
          }],
        },
        isShowCascader: true
      }
    },
    props: {
      shopInfoData: {
        type: Object,
        default: {}
      }
    },
    created() {

    },
    methods: {
      echoData() {
        this.shopRegister = this.shopInfoData
        //刷新级联选择器组件 否则无法显示
        this.isShowCascader = false
        setTimeout(() => {
          this.isShowCascader = true
        }, 100)
      },
      nextBack() {
        this.$emit('back')
      },
      next(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let obj = JSON.parse(JSON.stringify(this.shopRegister))
            obj.return_address = Number(obj.return_address[obj.return_address.length - 1])
            obj.companies_range = Number(obj.companies_range[obj.companies_range.length - 1])
            this.$emit("submit", obj)
          } else {
            return false
          }
        })
      }
    }

  }
</script>

<style lang="less" scoped>
  .shopInfo {
    width: 800px;
    margin: 0 auto;

    /deep/ .el-form-item__label {
      font-weight: 600;
      color: rgb(56, 55, 55);
      font-size: 16px;
      height: 50px;
      line-height: 50px;
    }

    /deep/ .el-input__inner {
      height: 50px;
      border: 1px solid #bfc3c4;
      background: #ffffff;
      color: #333;
      font-size: 16px;
    }

    .button-okz {
      padding-left: 300px;
      display: flex;
      flex-direction: column;
      padding-bottom: 60px;

      .go-one {
        width: 280px;
        color: #6e5121;
        font-size: 18px;
        border-radius: 8px;
        background: rgba(205, 161, 86, .7);
        height: 44px;
        line-height: 44px;
        border: none;
        margin-top: 40px;
        cursor: pointer;
      }

      .go-two {
        width: 280px;
        color: #cda156;
        font-size: 18px;
        background: rgba(255, 255, 255, .7);
        border: none;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
</style>
