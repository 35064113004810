<template>
  <div class="businessesIn">
    <div class="step-auction">
      <el-steps :active="active">
        <el-step title="基本信息"></el-step>
        <el-step title="店铺信息"></el-step>
        <el-step title="提交成功"></el-step>
      </el-steps>
    </div>
    <basicInfo ref="basicInfo" v-show="active==1" @next="next" :basicInfoData="basicInfoData"></basicInfo>
    <shopInfo ref="shopInfo" v-show="active==2" @back="back" :shopInfoData="shopInfoData" @submit="applyEntryShop">
    </shopInfo>
    <success v-show="active==3"></success>
  </div>
</template>

<script>
  import {
    applyEntryShop,
    getMallApplyShopInfo
  } from '../../api/index'
  import basicInfo from './components/basicInfo'
  import shopInfo from './components/shopInfo'
  import success from './components/success'
  export default {
    name: 'businessesIn',
    components: {
      basicInfo,
      shopInfo,
      success
    },
    data() {
      return {
        active: 1,
        basicInfoData: {},
        shopInfoData: {},

      }
    },
    created() {
      if (this.$route.query.edit == 1) {
        this.getMallApplyShopInfo()
      }
    },
    methods: {
      back(e) {
        this.active = 1
      },
      next(e) {
        this.active = 2
        this.basicInfoData = e //拿到基础信息data
      },
      async applyEntryShop(e) {
        let submitData = {}
        this.shopInfoData = e //店铺信息data
        //合并数据
        submitData = {
          ...this.basicInfoData,
          ...this.shopInfoData
        }
        delete submitData.rePassword //删除不需要的值
        if (this.$route.query.edit == 1) { //编辑
          submitData.edit_status = 2
          submitData.password = ''
        } else { //注册
          submitData.edit_status = 1
          submitData.password = this.$md5(submitData.password) //密码加密
        }
        const res = await applyEntryShop(submitData)
        if (res.flag === true) {
          this.active = 3
        } else {
          this.$message.error(res.msg)
        }
      },
      async getMallApplyShopInfo() {
        const res = await getMallApplyShopInfo({
          authorization: localStorage.getItem('accessToken'),
          enterprise_id: Number(localStorage.getItem('enterprise_id'))
        })
        if (res.flag === true) {
          this.basicInfoData = {
            user_name: res.data.user_name, //用户名
            user_phone: res.data.user_phone, //用户手机号
            user_phone_verify_code: '', //手机号验证码
            password: res.data.password, //密码
            rePassword: res.data.password, //确认密码
            vertical: res.data.vertical, //主体类型 1 企业 2 个体户
            open_account_name: res.data.open_account_name, //开户人名
            bank_code: res.data.bank_code, //银行卡号
            bank_name: res.data.bank_name, //银行名称
            operator_name: res.data.operator_name, //法人/经营者姓名
            certificate_no: res.data.certificate_no, //法人/经营者身份证号
            certificate_photo: res.data.certificate_photo, //法人/经营者证件照头像面
            certificate_back_photo: res.data.certificate_back_photo, //法人/经营者证件照国徽面
            enterprise_name: res.data.enterprise_name, //企业名称
            enterprise_code: res.data.enterprise_code, //企业信用代码
            business_license: res.data.business_license //营业执照
          }
          this.$nextTick(() => {
            this.$refs.basicInfo.echoData()
          })
          let companies_range = res.data.companies_range_levels.split(',')
          companies_range = companies_range.map(item => {
            return item = Number(item)
          })
          let return_address = res.data.return_address_levels.split(',')
          //过滤空字段
          let newAddr = []
          return_address.forEach((item, index) => {
            if (index > 0) {
              if (item) {
                newAddr.push(item)
              }
            }
          })
          this.shopInfoData = {
            shop_name: res.data.shop_name, //店铺名称
            principal_name: res.data.principal_name, //负责人姓名
            phone: res.data.phone, //负责人手机号
            other_contact: res.data.other_contact, //其他联系人
            companies_range: companies_range, //经营品类
            return_contact: res.data.return_contact, //退货联系人
            return_contact_phone: res.data.return_contact_phone, //退货联系方式
            return_address: newAddr, //退货地址
            return_address_info: res.data.return_address_info //退货详细地址
          }
          this.$nextTick(() => {
            this.$refs.shopInfo.echoData()
          })
        } else {
          this.$message.error(res.msg)
        }
      }
    }

  }
</script>

<style lang="less" scoped>
  .businessesIn {
    .step-auction {
      padding: 0 650px;
      margin: 20px 0;

      /deep/.el-step__head.is-process {
        color: #C0C4CC;
        border-color: #C0C4CC;
      }

      /deep/.el-step__title.is-process {
        color: #C0C4CC;
      }

      /deep/ .el-step.is-horizontal {
        flex-basis: 0% !important;
      }

      /deep/ .el-step__line-inner {
        color: #cda156;
      }

      /deep/ .el-step__head.is-finish {
        color: #cda156;
        border-color: #cda156;
      }

      /deep/ .el-step__icon.is-text {
        margin-right: 274px !important;
      }

      /deep/ .el-step__title.is-finish {
        color: #cda156;
      }

      /deep/ .el-step__main {
        width: 70px;
        margin-left: -18px;
      }
    }
  }
</style>
