<template>
  <div class="basicInfo">
    <el-form :model="shopRegister" :rules="rules" ref="shopRegister" label-width="140px">
      <el-form-item label="用户名" prop="user_name">
        <el-input :disabled="this.$route.query.edit==1" v-model="shopRegister.user_name" placeholder="请输入用户名"
          maxlength="50"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="user_phone">
        <el-input :disabled="this.$route.query.edit==1" v-model="shopRegister.user_phone" placeholder="请输入手机号"
          maxlength="11">
        </el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="user_phone_verify_code" v-if="this.$route.query.edit!=1">
        <div class="code-input">
          <el-input v-model="shopRegister.user_phone_verify_code" placeholder="请输入验证码" maxlength="4">
          </el-input>
          <span @click="sendCode()">{{yzmtext}}</span>
        </div>
      </el-form-item>
      <el-form-item label="密码" prop="password" v-if="this.$route.query.edit!=1">
        <el-input v-model="shopRegister.password" type="password" show-password
          placeholder="密码要求为8-16位数字、英文字母、特殊符号中至少两种类型以上的组合" maxlength="16">
        </el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="rePassword" v-if="this.$route.query.edit!=1">
        <el-input v-model="shopRegister.rePassword" type="password" show-password placeholder="请再次输入密码" maxlength="16">
        </el-input>
      </el-form-item>
      <el-form-item label="主体类型" prop="vertical">
        <el-select style="width: 100%;" @change="verticalChange" v-model="shopRegister.vertical" placeholder="企业/个体">
          <el-option v-for="item in verticalList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户人名" prop="open_account_name">
        <el-input v-model="shopRegister.open_account_name" placeholder="请输入开户人名" maxlength="50">
        </el-input>
      </el-form-item>
      <el-form-item label="银行卡号" prop="bank_code">
        <el-input v-model="shopRegister.bank_code" placeholder="请输入银行卡号" maxlength="19">
        </el-input>
      </el-form-item>
      <el-form-item label="结算银行名称" prop="bank_name">
        <el-input v-model="shopRegister.bank_name" placeholder="请输入结算银行名称" maxlength="50">
        </el-input>
      </el-form-item>
      <el-form-item :label="infolabel+'姓名'" prop="operator_name">
        <el-input v-model="shopRegister.operator_name" :placeholder="'请输入'+infolabel+'姓名'">
        </el-input>
      </el-form-item>
      <el-form-item :label="infolabel+'身份证号'" prop="certificate_no">
        <el-input v-model="shopRegister.certificate_no" :placeholder="'请输入'+infolabel+'身份证号'"> maxlength="18">
        </el-input>
      </el-form-item>
      <el-form-item :label="infolabel+'身份证照片'" prop="certificate_photo">
        <div class="certificate-img">
          <div class="upload-img">
            <el-upload :class="{ disabled: uploadDisabled }" :on-change="handleChange" action=""
              list-type="picture-card" :on-preview="handlePictureCardPreview" :limit="limitOne"
              :on-remove="handleRemove" :http-request='getFileOne' :file-list="fileListOne"
              accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .BMP">
              <div class="upload-hint">
                <img src="../../../assets/images/shangchuantupian备份 2@2x.png" />
                <span>上传人像面</span>
              </div>
            </el-upload>
          </div>
          <div class="upload-img">
            <el-upload :class="{ disabled: uploadDisabledTwo }" :on-change="handleChangeTwo" action=""
              list-type="picture-card" :on-preview="handlePictureCardPreview" :limit="limitOne"
              :on-remove="handleRemoveTwo" :http-request='getFileTwo' :file-list="fileListTwo"
              accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .BMP">
              <div class="upload-hint">
                <img src="../../../assets/images/shangchuantupian备份 2@2x.png" />
                <span>上传国徽面</span>
              </div>
            </el-upload>
          </div>
        </div>
        <!-- <div class="sizeHint">上传封面图片文件不能大于3M，格式可以上传jpg、png、jpeg</div> -->
      </el-form-item>
      <el-form-item :label="infolabelTwo+'名称'" prop="enterprise_name">
        <el-input v-model="shopRegister.enterprise_name" :placeholder="'请输入'+infolabelTwo+'名称'">
        </el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="enterprise_code">
        <el-input maxlength="18" v-model="shopRegister.enterprise_code" placeholder="请输入统一社会信用代码">
        </el-input>
      </el-form-item>
      <el-form-item label="营业执照" prop="business_license">
        <div class="upload-img">
          <el-upload :class="{ disabled: uploadDisabledThree }" :on-change="handleChangeThree" action=""
            list-type="picture-card" :on-preview="handlePictureCardPreview" :limit="limitOne"
            :on-remove="handleRemoveThree" :http-request='getFileThree' :file-list="fileListThree"
            accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .BMP">
            <div class="upload-hint">
              <img src="../../../assets/images/shangchuantupian备份 2@2x.png" />
            </div>
          </el-upload>
          <!-- <span class="upload-img-title">请上传营业执照</span> -->
        </div>
      </el-form-item>
    </el-form>
    <div class="button-okz">
      <button class="go-one" @click="next('shopRegister')">下一步</button>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
  import {
    uploadFile,
    sendPhoneCode
  } from '../../../api/index'
  import OSS from 'ali-oss'
  export default {
    name: 'basicInfo',
    data() {
      const certificate_photo = (rule, value, callback) => {
        if (this.shopRegister.certificate_photo === '') {
          callback(new Error('请上传（法人/经营者）身份证照片人像面'))
        } else if (this.shopRegister.certificate_back_photo === '') {
          callback(new Error('请上传（法人/经营者）身份证照片国徽面'))
        } else {
          callback()
        }
      }
      const testPassword = /(?![A-Z]*$)(?![a-z]*$)(?![0-9]*$)(?![^a-zA-Z0-9]*$)/
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else if (!testPassword.test(value)) {
          callback(new Error('密码格式有误，请重新输入'))
        } else {
          if (this.shopRegister.rePassword !== '') {
            this.$refs.shopRegister.validateField('rePassword')
          }
          callback()
        }
      }
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.shopRegister.password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        shopRegister: {
          user_name: '', //用户名
          user_phone: '', //用户手机号
          user_phone_verify_code: '', //手机号验证码
          password: '', //密码
          rePassword: '', //确认密码
          vertical: 1, //主体类型 1 企业 2 个体户
          open_account_name: '', //开户人名
          bank_code: '', //银行卡号
          bank_name: '', //银行名称
          operator_name: '', //法人/经营者姓名
          certificate_no: '', //法人/经营者身份证号
          certificate_photo: '', //法人/经营者证件照头像面
          certificate_back_photo: '', //法人/经营者证件照国徽面
          enterprise_name: '', //企业名称
          enterprise_code: '', //企业信用代码
          business_license: '', //营业执照
        },
        rules: {
          user_name: [{
            required: true,
            message: '请输入用户名',
            trigger: 'change'
          }],
          user_phone: [{
              required: true,
              message: '请输入手机号',
              trigger: 'change'
            },
            {
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: '请输入正确的手机号码',
              trigger: 'change'
            }
          ],
          user_phone_verify_code: [{
            required: true,
            message: '请输入验证码',
            trigger: 'change'
          }, {
            pattern: /^\d{4}$/,
            message: '请输入正确的验证码',
            trigger: 'change'
          }],
          password: [{
              required: true,
              validator: validatePass,
              trigger: 'change'
            },
            {
              min: 8,
              max: 16,
              message: '密码长度不正确',
              trigger: 'change'
            }
          ],
          rePassword: [{
              required: true,
              validator: validatePass2,
              trigger: 'change'
            },
            {
              min: 8,
              max: 16,
              message: '密码长度不正确',
              trigger: 'change'
            }

          ],
          vertical: [{
            required: true,
            message: '请选择主题类型',
            trigger: 'change'
          }],
          open_account_name: [{
            required: true,
            message: '请输入开户人名',
            trigger: 'change'
          }, {
            pattern: /^[\u4e00-\u9fa5]+$/,
            message: '开户人名必须是中文',
            trigger: 'change'
          }],
          bank_code: [{
            required: true,
            message: '请输入银行卡号',
            trigger: 'change'
          }, {
            pattern: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/,
            message: '请输入正确的银行卡号',
            trigger: 'change'
          }],
          bank_name: [{
            required: true,
            message: '请输入结算银行名称',
            trigger: 'change'
          }],
          operator_name: [{
            required: true,
            message: '请输入（法人/经营者）姓名',
            trigger: 'change'
          }, {
            pattern: /^[\u4e00-\u9fa5]+$/,
            message: '（法人/经营者）姓名必须是中文',
            trigger: 'change'
          }],
          certificate_no: [{
            required: true,
            message: '请输入（法人/经营者）身份证号',
            trigger: 'change'
          }, {
            pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: '请输入正确的（法人/经营者）身份证号',
            trigger: 'change'
          }],
          certificate_photo: [{
            required: true,
            validator: certificate_photo,
            trigger: 'change'
          }],
          enterprise_name: [{
            required: true,
            message: '请输入（企业/个人）名称',
            trigger: 'change'
          }],
          enterprise_code: [{
              required: true,
              message: '请输入统一社会信用代码',
              trigger: 'change'
            },
            {
              pattern: /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/,
              message: '请输入15/18位数字或大写英文字母',
              trigger: 'change'
            }
          ],
          business_license: [{
            required: true,
            message: '请上传营业执照',
            trigger: 'change'
          }],
        },
        verticalList: [{
            id: 1,
            name: '企业'
          },
          {
            id: 2,
            name: '个体户'
          }
        ],
        uploadDisabled: false,
        uploadDisabledTwo: false,
        uploadDisabledThree: false,
        limitOne: 1,
        fileListOne: [],
        fileListTwo: [],
        fileListThree: [],
        dialogImageUrl: '',
        dialogVisible: false,
        ossToken: null,
        btnBool: false,
        yzmtext: '获取验证码',
        infolabel: '法人',
        infolabelTwo:'企业'
      }
    },
    //属性
    props: {
      basicInfoData: {
        type: Object,
        default: {}
      }
    },
    created() {},
    methods: {
      // 点击获取验证码封装
      onCode() {
        this.btnBool = true
        let i = 60
        var timer = setInterval(() => {
          this.yzmtext = '' + i + 's'
          i--
          if (i < 0) {
            this.btnBool = false
            this.yzmtext = '获取验证码'
            clearInterval(timer)
          }
        }, 1000)
      },
      echoData() {
        this.shopRegister = this.basicInfoData
        //回显图片
        this.fileListOne.push({
          url: this.basicInfoData.certificate_photo
        })
        this.fileListTwo.push({
          url: this.basicInfoData.certificate_back_photo
        })
        this.fileListThree.push({
          url: this.basicInfoData.business_license
        })
        //隐藏上传加号图标
        this.uploadDisabled = true;
        this.uploadDisabledTwo = true;
        this.uploadDisabledThree = true;
      },
      async sendCode() {
        if (this.yzmtext != '获取验证码') return
        if (this.shopRegister.user_phone === '') return this.$message.error('请先输入手机号')
        const res = await sendPhoneCode({
          mobile: this.shopRegister.user_phone,
          scene_type: 1
        })
        if (res.flag === true) {
          this.onCode()
          this.$message.success('发送成功')
        } else {
          this.$message.error(res.msg)
        }
      },
      next(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$emit("next", this.shopRegister)
          } else {
            return false
          }
        })
      },
      handleChange(file, fileList) {
        if (fileList.length >= 1) {
          this.uploadDisabled = true;
        }
      },
      handleChangeTwo(file, fileList) {
        if (fileList.length >= 1) {
          this.uploadDisabledTwo = true;
        }
      },
      handleChangeThree(file, fileList) {
        if (fileList.length >= 1) {
          this.uploadDisabledThree = true;
        }
      },
      verticalChange(e) {
        this.$refs.shopRegister.clearValidate()
        this.infolabel = e == 1 ? '法人' : '经营者'
        this.infolabelTwo = e == 1 ? '企业' : '个人'
      },
      handlePictureCardPreview(file) { //放大
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      handleRemove(file, fileList) {
        this.uploadDisabled = false;
        this.shopRegister.certificate_photo = ''
        this.$refs.shopRegister.validateField('certificate_photo')
      },
      handleRemoveTwo(file, fileList) {
        this.uploadDisabledTwo = false;
        this.shopRegister.certificate_back_photo = ''
        this.$refs.shopRegister.validateField('certificate_photo')
      },
      handleRemoveThree(file, fileList) {
        this.uploadDisabledThree = false;
        this.shopRegister.business_license = ''
        this.$refs.shopRegister.validateField('business_license')
      },
      // oss token
      async uploadFile() {
        const res = await uploadFile()
        if (res.code === 0) {
          this.ossToken = res.data
        } else {
          this.$message.error(res.msg)
        }
      },
      // 上传身份证人像
      async getFileOne(event) {
        this.shopRegister.certificate_photo = await this.getUpImg(event.file)
        this.$refs.shopRegister.validateField('certificate_photo')
      },
      // 上传身份证国徽
      async getFileTwo(event) {
        this.shopRegister.certificate_back_photo = await this.getUpImg(event.file)
        this.$refs.shopRegister.validateField('certificate_photo')
      },
      // 上传营业执照
      async getFileThree(event) {
        this.shopRegister.business_license = await this.getUpImg(event.file)
        this.$refs.shopRegister.validateField('business_license')
      },
      getUpImg(file) { //获取上传图片路径
        return new Promise(async (resolve, reject) => {
          const uploadHost = 'https://pic.easyebid.com'
          if (!this.ossToken) await this.uploadFile()
          var client = new OSS({
            region: 'oss-cn-beijing',
            accessKeyId: this.ossToken.accessKeyId,
            accessKeySecret: this.ossToken.accessKeySecret,
            bucket: 'yideonline',
            stsToken: this.ossToken.securityToken
          })
          const type = file.name.split('.')
          const len = type.length
          const fileName = this.uuid() + '.' + type[len - 1]
          const result = await client.put(`cer/${fileName}`, file)
          if (result.res.statusCode === 200) {
            const successImg = `${uploadHost}/${result.name}`
            resolve(successImg)
          } else {
            this.$message.error('上传失败')
            reject()
          }
        })
      },
      //获取唯一标识
      uuid() {
        let s = [];
        const hexDigits = '0123456789abcdef';
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4';
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';
        return s.join('');
      }
    }

  }
</script>

<style lang="less" scoped>
  .basicInfo {
    width: 800px;
    margin-left: 480px;

    /deep/.el-upload-list__item {
      transition: none;
    }

    /deep/ .el-form-item__label {
      font-weight: 600;
      color: rgb(56, 55, 55);
      font-size: 16px;
      height: 50px;
      line-height: 50px;
    }

    /deep/ .el-input__inner {
      height: 50px;
      border: 1px solid #bfc3c4;
      background: #ffffff;
      color: #333;
      font-size: 16px;
    }

    /deep/ .el-upload--picture-card {
      width: 120px;
      height: 80px;
      background: #fff;
      border: 1px solid #bfc3c4;
      line-height: 80px;
      border-radius: 0;
    }

    .code-input {
      display: flex;

      span {
        padding: 0 20px;
        height: 50px;
        background: #CDA156;
        text-align: center;
        line-height: 50px;
        color: #FFFFFF;
        font-size: 16px;
        cursor: pointer;
        flex-shrink: 0;
        position: relative;
      }
    }

    .upload-img {
      display: flex;
      align-items: center;
      margin-right: 15px;

      .upload-img-title {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        margin-left: 20px;
      }
    }

    .certificate-img {
      display: flex;
    }

    .sizeHint {
      color: #999;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }

    .upload-hint {
      width: 120px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        color: #999999;
        font-size: 12px;
        height: 30px;
        line-height: 30px;

      }
    }

    .disabled {
      /deep/.el-upload--picture-card {
        display: none !important;
      }
    }

    .button-okz {
      padding-left: 300px;
      display: flex;
      flex-direction: column;
      padding-bottom: 60px;

      .go-one {
        width: 280px;
        color: #6e5121;
        font-size: 18px;
        border-radius: 8px;
        height: 44px;
        line-height: 44px;
        border: none;
        margin-top: 40px;
        cursor: pointer;
        background: rgba(205, 161, 86, .7);
      }
    }
  }
</style>
